import React from "react";
import '../styles.css';

function Blog() {
    return(
        <div className="body">
            <header className= "default-text">
                <p>
                    Blog
                </p>
            </header>
        </div>
    )
}

export default Blog;