import React from "react";
import '../styles.css';

function Programming() {
    return(
        <div className="body">
            <header className= "default-text">
                <p>
                    Programming
                </p>
            </header>
        </div>
    )
}

export default Programming;