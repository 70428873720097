import React from "react";
import '../styles.css';

function Writeups() {
    return(
        <div className="body">
            <header className= "default-text">
                <p>
                    Write-ups
                </p>
            </header>
        </div>
    )
}

export default Writeups;